import React, { useEffect, useState } from 'react';
import styles from '../LoginStyle.module.scss';
import { Button, CircularProgress, Fade } from '@material-ui/core';
import { useFormik } from 'formik';
import mainLogo from 'assets/logo.png';
import { FormField } from 'src/components';
import { ForgotPassword } from './ResetPassword';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { VALIDATE_RESET_TOKEN } from 'src/context/services';
import { PasswordExpiredDialog } from './PasswordExpiredDialog';

export function LoginForm({ auth }) {
    const { t } = useTranslation();
    const query = new URLSearchParams(useLocation().search);
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const [passwordExpired, setPasswordExpired] = useState(false);
    const [resetPassword, setResetPassword] = useState(false);
    const [token, setToken] = useState(query.get("token"));

    const validate = values => {
        const errors = {};

        if (!token && !values?.username) {
            errors.username = t('generic:errors.valueRequired');
        }

        if (!token && !values?.password) {
            errors.password = t('generic:errors.valueRequired');
        }

        if(token || passwordExpired){
            let _containsUpperCase = new RegExp("^(?=.*[A-Z])");
            let _containsLowerCase = new RegExp("^(?=.*[a-z])");
            let _containsNumbers = new RegExp("^(?=.*[0-9])");

            // eslint-disable-next-line no-useless-escape
            let _containsSpecial = new RegExp("(?=.*[-!@#\$%\^&\*])");
            let _minLength = new RegExp("(?=.{8,20})");
    
            if (values?.newpassword !== values?.repeatnewpassword) {
                errors.repeatnewpassword = t('generic:errors.passwordNotTheSame');
            }

            if (!_containsUpperCase.test(values.newpassword)) {
                errors['newpassword'] = t('generic:errors.upperCaseLetterRequired');
            } else if (!_containsLowerCase.test(values.newpassword)) {
                errors['newpassword'] = t('generic:errors.lowerCaseLetterRequired');
            } else if (!_containsNumbers.test(values.newpassword)) {
                errors['newpassword'] = t('generic:errors.numberRequired');
            } else if (!_containsSpecial.test(values.newpassword)) {
                errors['newpassword'] = t('generic:errors.specialLetterRequired');
            } else if (!_minLength.test(values.newpassword)) {
                errors['newpassword'] = t('generic:errors.passwordLengthRequired');
            }

            if (!_containsUpperCase.test(values.repeatnewpassword)) {
                errors['repeatnewpassword'] = t('generic:errors.upperCaseLetterRequired');
            } else if (!_containsLowerCase.test(values.repeatnewpassword)) {
                errors['repeatnewpassword'] = t('generic:errors.lowerCaseLetterRequired');
            } else if (!_containsNumbers.test(values.repeatnewpassword)) {
                errors['repeatnewpassword'] = t('generic:errors.numberRequired');
            } else if (!_containsSpecial.test(values.repeatnewpassword)) {
                errors['repeatnewpassword'] = t('generic:errors.specialLetterRequired');
            } else if (!_minLength.test(values.repeatnewpassword)) {
                errors['repeatnewpassword'] = t('generic:errors.passwordLengthRequired');
            }

            if (!values?.newpassword) {
                errors.newpassword = t('generic:errors.valueRequired');
            }
    
            if (!values?.repeatnewpassword) {
                errors.repeatnewpassword = t('generic:errors.valueRequired');
            }

            if(passwordExpired && values.password === values.newpassword && values.newpassword === values?.repeatnewpassword){
                errors.newpassword = t('generic:errors.passwordMustBeDifferent');
                errors.repeatnewpassword = t('generic:errors.passwordMustBeDifferent');
            }
        }

        return errors;
    }

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            newpassword: '',
            repeatnewpassword: ''
        },
        validate,
        onSubmit: values => {
            if(token){
                setLoading(true);
                auth.resetPassword({ token: token, password: values.newpassword });
            }else if(passwordExpired){
                setLoading(true);
                auth.changeExpiredPassword({ username: values.username, oldPassword: values.password, newPassword: values.newpassword }).then(() => {
                setLoading(false);
                setToken(null);
                    setPasswordExpired(false);
                    setResetPassword(false);
                    history.push("/");
                });
            }else{
                setLoading(true);
                auth.signIn({
                    username : values.username,
                    password : values.password.trim()
                }).then(data => {
                    setLoading(false);
                }, error => {
                    if(error === 'password_expired'){
                        setPasswordExpired(true);
                        setLoading(false);
                    }
                    setLoading(false);
                });
            }
        },
        validateOnChange: false,
        validateOnMount: false
    });

    useEffect(() => {
        if (window.location.href.includes('/recovery') && token) {
            VALIDATE_RESET_TOKEN(token).then((response) => {
                setResetPassword(true);
            }, () => {
                setToken(null);
                history.push("/");
            });
        }
    }, [history, token]);

    return (
        <Fade in={!auth._data.profile} duration={500}>
            <div className={styles['login']}>
                <form className={styles['login__formContainer']} onSubmit={formik.handleSubmit}>
                    <div className={styles['logoContainer']}>
                        <img src={mainLogo} alt="ParagonLogo" className={styles['logoContainer__logo']} />
                    </div>
                    <div className={styles['inputContainer']}>
                        {!token && <div className={styles['inputContainer__input']}>
                            <FormField autoComplete="username" fullWidth formik={formik} name="username" label="Username" />
                        </div>}
                        {!token && <div className={styles['inputContainer__input']}>
                            <FormField autoComplete="current-password" fullWidth formik={formik} name="password" label="Password" type="password" />
                        </div>}

                        {token && <div className={styles['inputContainer__input']}>
                            <FormField autoComplete="current-password" fullWidth formik={formik} name="newpassword" label="New password" type="password" />
                        </div>}

                        {token && <div className={styles['inputContainer__input']}>
                            <FormField autoComplete="current-password" fullWidth formik={formik} name="repeatnewpassword" label="Repeat new password" type="password" />
                        </div>}
                    </div>
                    <div className={styles['buttonContainer']}>
                        {!token && <Button variant="contained" classes={{ root: styles['buttonContainer__button'] }} type="submit" disabled={auth.isPending || loading}>{(auth.isPending || loading) ? <CircularProgress className={styles['buttonContainer__pendingCircle']} /> : t('login:signIn')}</Button>}
                        {token && <Button variant="contained" classes={{ root: styles['buttonContainer__button'] }} type="submit" disabled={auth.isPending || loading}>{(auth.isPending || loading) ? <CircularProgress className={styles['buttonContainer__pendingCircle']} /> : t('login:changePassword')}</Button>}
                    </div>
                    {!token && !resetPassword && <ForgotPassword auth={auth} />}
                    {
                        token &&
                        <div className={styles['resetPasswordContainer']}>
                            <span className={styles['resetPasswordContainer__text']} onClick={() => { setToken(null); setPasswordExpired(false); history.push("/");}}> {t('login:backToLogin')}</span>
                        </div>
                    }
                    <PasswordExpiredDialog formik={formik} openDialog={passwordExpired} handleClose={() => setPasswordExpired(false)} loading={loading} />
                </form>
            </div>
        </Fade>
    );
}