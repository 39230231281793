import React from 'react';
import styles from './TopBar.module.scss';
import { useHistory } from 'react-router';
import { useProfile } from 'src/hooks/User/useProfile';
import { IsTablet } from 'src/hooks/MediaQuery/isTablet';
import MainLogo from 'assets/logo.png';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Avatar from 'avataaars'

export const TopBar = ({ openMenu }) => {
    const profile = useProfile();
    const history = useHistory();
    const isTablet = IsTablet();

    return(
        <div className={styles['topbarContainer']}>
            {   isTablet && <IconButton onClick={openMenu}><MenuIcon /></IconButton>}
            <div className={styles['topbarContainer__logoContainer']}>
                <img src={MainLogo} alt="ParagonLogo" className={styles['topbarContainer__logoContainer-logo']}/>
            </div>
            { !isTablet && <div className={styles['topbarContainer-grow']} /> }
            <div className={styles['topbarContainer__userIcon']}>
                {!isTablet && <span className={styles['topbarContainer__userIcon-text']}>{ profile.fullName }</span>}
                <IconButton className={styles['topbarContainer__userIcon-button']} size="small" onClick={() => history.push('/myprofile')}>
                    <div className={!profile.hasAvatar ? styles['initialIconContainer-small'] : ''}>
                        {
                            profile.hasAvatar ? <Avatar {...profile.avatar} /> : profile.initials
                        }
                    </div>
                </IconButton>
            </div>
        </div>
    )
}