import React from 'react';
import styles from '../LoginStyle.module.scss';
import { Button, CircularProgress, Fade, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import mainLogo from 'assets/logo.png';
import MaskedInput from 'react-text-mask';
import { useTranslation } from 'react-i18next';

export function TwoFactorAuthentication({ auth }) {
    const { t } = useTranslation();
    const validate = values => {
        const errors = {};

        if (!values?.code) {
            errors.code = '';
        }

        return errors;
    }

    const formik = useFormik({
        initialValues: {
            code: '',
            userUuid: auth._data.profile.userUuid
        },
        validate,
        onSubmit: values => {
            auth.checkOTP(values);
        }
    });

    return (
        <Fade in={true} duration={500}>
            <div className={styles['login']}>
                <form className={styles['login__formContainer']} onSubmit={formik.handleSubmit}>
                    <div className={styles['logoContainer']}>
                        <img src={mainLogo} alt="ParagonLogo" className={styles['logoContainer__logo']} />
                    </div>
                    <div className={styles['inputContainer']}>
                        <div className={styles['inputContainer__input']}>
                            <MaskedInput
                                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                placeholderChar={'\u2000'}
                                name={"code"}
                                value={formik.values.code}
                                onChange={(event) => {
                                    formik.handleChange(event);
                                    if(event.target.value.length >= 6)
                                    formik.handleSubmit();
                                }}
                                onBlur={formik.handleBlur}
                                showMask
                                guide={false}
                                render={(ref, innerProps) => (
                                    <TextField {...innerProps} inputRef={ref} fullWidth error={formik.touched.code && formik.errors.code !== undefined} helperText={formik.errors.code} name="code" label="OTP" variant="outlined" color="primary" />
                                )}
                            />
                        </div>
                    </div>
                    <div className={styles['buttonContainer']}>
                        <Button variant="contained" classes={{ root: styles['buttonContainer__button'] }} type="submit" disabled={auth.isPending}>{auth.isPending ? <CircularProgress className={styles['buttonContainer__pendingCircle']} /> : t('login:verifyOTP')}</Button>
                    </div>
                    <div className={styles['resetPasswordContainer']}>
                        <span className={styles['resetPasswordContainer__text']} onClick={auth.signOut}>{t('generic:cancel')}</span>
                    </div>
                </form>
            </div>
        </Fade>
    );
}